<template>
  <AuthContainer view="login" />
</template>

<script setup lang="ts">
definePageMeta({
  auth: false,
  layout: 'auth',
  layoutConfig: {
    showFreeTrial: true,
  },
})

const { data } = useAuth()
const { isDaemonContext } = useClientContext()

if (data.value) {
  if (isDaemonContext.value) {
    navigateTo('/devices/handoff')
  } else {
    navigateTo('/')
  }
}
</script>
